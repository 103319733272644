@import url("https://fonts.googleapis.com/css2?family=Roboto:300,400,500,700&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

#map {
  height: 100vh;
}

.custom-icon {
  border-radius: 50%;
  width: 24px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.custom-icon.black-icon {
  background-color: #000000;
}

.icon-content {
  font-size: 14px;
}

.first-icon {
  background-color: inherit;
}

.first-icon.icon-content {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  color: #f2f2f2;
  background-color: #444;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.overlay-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-input {
  margin: 0 10px;
  padding: 5px;
  font-size: 14px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
}

.overlay-controls > p {
  margin: 0 10px; /* Add 5px margin before and after the text */
}

.start-button {
  margin-top: 10px;
  width: 100%;
}

.overlay-button-minus,
.overlay-button-plus,
.start-button {
  /* Common styles */
  padding: 10px 15px;
  font-size: 16px;
  background-color: #616161; /* Dark grey background color */
  color: #f2f2f2; /* Light grey text color */
  border: none;
  border-radius: 0; /* Square corners */
  cursor: pointer;
}

.disable-click {
  cursor: not-allowed;
}

.banner-details-page {
  display: flex;
}

.banner-details-container {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-details-card {
  max-width: 400px;
  margin: 0 auto;
}

.map-container {
  flex: 3;
  height: 100vh;
}

@media (max-width: 768px) {
  .banner-details-page {
    flex-direction: column;
  }

  .banner-details-container {
    order: 1;
  }

  .map-container {
    order: 1;
    height: 50vh;
  }
}
